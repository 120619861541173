import {
  ContentBox,
  FormikCheck,
  FormikControl,
  FormSection,
  NotificationType,
  useNotification,
} from "@gelsenwasser/react";
import { Formik, Form as FormikForm } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { log } from "../common/Logger";
import { ApiContext } from "../services/ApiContext";
import {
  AuthData,
  SESSIONITEM_VERTRAGSDATEN,
  Vertragsdaten,
  Zustimmung,
  ZustimmungAntwort,
  ZustimmungOptions,
  ZustimmungOptionsEnum,
} from "./Types";
import { ZustimmungSchema } from "./Validation";
import { ReactComponent as AblehnenIcon } from "../assets/ablehnen.svg";
import { ReactComponent as AnnehmenIcon } from "../assets/annehmen.svg";
import { ModalLoader } from "../components/ZahnradLoader";
import Bestaetigung from "./Bestaetigung";
import { useHistory } from "react-router-dom";
import { useBranding } from "./BrandingContext";
import styles from "./Zustimmung.module.scss";
import AlertTriangle from "../components/AlertTriangle";

enum FormStates {
  Zustimmung,
  Bestaetigung,
}

const Gestattungsvertrag: React.FC = () => {
  // hooks
  const { api } = useContext(ApiContext);
  const history = useHistory();
  const { addNotification } = useNotification();
  // states
  const [authData, setAuthData] = useState<AuthData>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormStates>(FormStates.Zustimmung);
  const [zustimmungAntwort, setZustimmungAntwort] = useState<ZustimmungAntwort>(
    { emailAdresse: "", emailGewuenscht: false, zustimmung: false }
  );
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    const data = sessionStorage.getItem(SESSIONITEM_VERTRAGSDATEN);
    if (data) {
      setAuthData(JSON.parse(data));
    } else {
      history.push("/");
    }
  }, [history]);
  // daten
  const getWiderrufsDatum = () => {
    if (authData) {
      const antwortAm = new Date(authData.vertragsdaten.antwortAm);
      antwortAm.setDate(new Date(antwortAm).getDate() + 14);
      return antwortAm.toLocaleDateString();
    }
    return "";
  };
  // handler
  const doConsent = (values: Zustimmung, actions: any) => {
    setShowLoader(true);
    const zustimmungAntwort: ZustimmungAntwort = {
      emailAdresse: values.email ?? "",
      emailGewuenscht: values.emailGewuenscht,
      zustimmung: values.istZustimmung === ZustimmungOptions.Zustimmung,
    };
    setZustimmungAntwort(zustimmungAntwort);
    api
      .post("vertrag", zustimmungAntwort)
      .then((res) => {
        log.debug({ obj: res }, "got response");
        actions.setSubmitting(false);
        setFormState(FormStates.Bestaetigung);
      })
      .catch((err) => {
        setShowLoader(false);
        log.error(err);
        addNotification(err.message, "Fehler", NotificationType.Alert);
        actions.setSubmitting(false);
      });
  };

  const handleCancel = () => {
    sessionStorage.clear();
    history.push("/");
  };

  const handleDokumentDownload = async () => {
    api
      .get("vertrag", {
        responseType: "arraybuffer",
      })
      .then((res) => {
        const arrayBuffer = res.data;
        const int8Array = new Uint8Array(arrayBuffer);

        const blob = new Blob([int8Array], { type: "application/pdf" });
        const oUrl = URL.createObjectURL(blob);
        const dLink = document.createElement("a");
        dLink.href = oUrl;
        dLink.download = "Vertrag.pdf";
        dLink.click();
        dLink.remove();
      })
      .catch((err) => {
        log.error(err);
        addNotification(
          err.response?.data?.detail,
          "Dokument konnte nicht heruntergeladen werden",
          NotificationType.Alert
        );
      });
  };

  const emptyZustimmung: Zustimmung = ZustimmungSchema.getDefault();
  if (authData) {
    emptyZustimmung.email = authData.vertragsdaten.vertragspartnerEmail;

    switch (authData?.vertragsdaten.antwort) {
      case ZustimmungOptionsEnum.zugestimmt:
        emptyZustimmung.istZustimmung = ZustimmungOptions.Zustimmung;
        break;

      case ZustimmungOptionsEnum.widerruf:
        emptyZustimmung.istZustimmung = ZustimmungOptions.Widerruf;
        break;
      default:
        break;
    }
  }

  return (
    <>
      {formState === FormStates.Zustimmung && (
        <Container className="d-flex justify-content-center p-5">
          <ContentBox title="Gestattungsvereinbarung">
            <Formik
              initialValues={emptyZustimmung}
              validationSchema={ZustimmungSchema}
              onSubmit={doConsent}
              enableReinitialize
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                getFieldMeta,
              }): JSX.Element => {
                const meta = getFieldMeta("istZustimmung");
                return (
                  <FormikForm onSubmit={handleSubmit}>
                    <FormSection title="">
                      <Row className="align-items-center">
                        <Col md={5}>
                          <p>
                            <strong>
                              Zwischen dem Grundstückseigentümer /
                              Gebäudeeigentümer
                            </strong>
                          </p>
                          <Eigentuemer vertrag={authData?.vertragsdaten} />
                          <p>
                            <strong>
                              für das Grundstück / Gebäude mit der Anschrift
                            </strong>
                          </p>
                          <ObjektAdresse vertrag={authData?.vertragsdaten} />
                        </Col>
                        <Col md={2} className="d-flex justify-content-center">
                          und der
                        </Col>
                        <Col md={5}>
                          <Gesellschaft vertrag={authData?.vertragsdaten} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            className={`d-flex justify-content-center align-items-center border-primary ${styles.downloadBorderStyle}`}
                            onClick={handleDokumentDownload}
                          >
                            <span>Download Vereinbarung</span>
                          </div>
                        </Col>
                      </Row>
                      {(authData?.vertragsdaten.antwort === 1 ||
                        authData?.vertragsdaten.antwort === 2) && (
                        <Row>
                          <Col>
                            Sie haben bereits eine
                            {authData?.vertragsdaten.antwort === 1 ? " " : "n "}
                            <strong>
                              {authData?.vertragsdaten.antwort === 1
                                ? "Zustimmung"
                                : "Widerruf"}
                            </strong>{" "}
                            erteilt. Sie können ihre Antwort noch bis zum{" "}
                            {getWiderrufsDatum()} ändern.
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="d-flex justify-content-center" md={5}>
                          <Card
                            className="rounded border-primary"
                            onClick={() => {
                              setFieldValue(
                                "istZustimmung",
                                ZustimmungOptions.Zustimmung
                              );
                            }}
                          >
                            <Card.Body className="bg-light d-flex flex-column align-items-center">
                              <AnnehmenIcon className="m-2" />
                              <FormikCheck
                                value={ZustimmungOptions.Zustimmung}
                                type="checkbox"
                                name="istZustimmung"
                                label="Ich habe die Gestattungsvereinbarung gelesen und stimme den enthaltenen Punkten zu"
                                id="istZustimmung_ja"
                                checked={
                                  values.istZustimmung ===
                                  ZustimmungOptions.Zustimmung
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue(
                                      "istZustimmung",
                                      ZustimmungOptions.Zustimmung
                                    );
                                  }
                                }}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col
                          className="d-flex justify-content-center"
                          md={{ span: 5, offset: 2 }}
                        >
                          <Card
                            className="rounded border-primary"
                            onClick={() => {
                              setFieldValue(
                                "istZustimmung",
                                ZustimmungOptions.Widerruf
                              );
                            }}
                          >
                            <Card.Body className="bg-light d-flex flex-column align-items-center">
                              <AblehnenIcon className="m-2" />
                              <FormikCheck
                                value={ZustimmungOptions.Widerruf}
                                type="checkbox"
                                name="istWiderruf"
                                label="Ich möchte der Gestattungsvereinbarung widersprechen"
                                id="istZustimmung_nein"
                                checked={
                                  values.istZustimmung ===
                                  ZustimmungOptions.Widerruf
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue(
                                      "istZustimmung",
                                      ZustimmungOptions.Widerruf
                                    );
                                  }
                                }}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      {meta.touched && meta.error && (
                        <Row>
                          <Col>{meta.error}</Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Infobox>
                            <FormikCheck
                              type="checkbox"
                              name="emailGewuenscht"
                              label="Ich möchte per Email eine Bestätigung erhalten."
                              id="emailGewuenscht"
                              checked={values.emailGewuenscht}
                              onChange={(e) => {
                                setFieldValue(
                                  "emailGewuenscht",
                                  e.target.checked
                                );
                              }}
                            />
                            {values.emailGewuenscht && (
                              <FormikControl
                                name="email"
                                label=""
                                autoComplete="off"
                                placeholder="Emailadresse"
                              />
                            )}
                            <FormikCheck
                              value={ZustimmungOptions.WiderrufGelesen}
                              type="checkbox"
                              name="widerrufGelesen"
                              label="Ich habe die Widerrufsbelehrung zur Kenntnis genommen."
                              id="widerrufGelesen_ja"
                              checked={
                                values.widerrufGelesen ===
                                ZustimmungOptions.WiderrufGelesen
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "widerrufGelesen",
                                  e.target.checked
                                    ? ZustimmungOptions.WiderrufGelesen
                                    : ZustimmungOptions.Unbekannt
                                );
                              }}
                            />
                          </Infobox>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button variant="secondary" onClick={handleCancel}>
                            Abbrechen
                          </Button>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button type="submit">Absenden</Button>
                        </Col>
                      </Row>
                    </FormSection>
                  </FormikForm>
                );
              }}
            </Formik>

            <ModalLoader show={showLoader}>
              Zustimmung/Widerruf wird gespeichert, bitte warten...
            </ModalLoader>
          </ContentBox>
        </Container>
      )}
      {formState === FormStates.Bestaetigung && (
        <>
          <Bestaetigung {...zustimmungAntwort} />
        </>
      )}
    </>
  );
};

interface VertragProps {
  vertrag?: Vertragsdaten;
}

const Eigentuemer: React.VFC<VertragProps> = ({ vertrag }) => {
  if (!vertrag) return null;
  const vertragspartnerName = `${vertrag.vertragspartnerVorname} ${vertrag.vertragspartnerNachname}`;
  if (vertrag.vertragspartnerFirma) {
    return (
      <p>
        {vertrag.vertragspartnerFirma}
      </p>
    );
  }
  return <p>{vertragspartnerName}</p>;
};

const Gesellschaft: React.VFC<VertragProps> = ({ vertrag }) => {
  if (!vertrag) return null;
  return (
    <>
      <p>
        {vertrag.objektGesellschaftAnzeigeName ?? vertrag.objektGesellschaftName} <br />
        {vertrag.objektGesellschaftAdresseStrasse}{" "}
        {vertrag.objektGesellschaftAdresseHausnummer},{" "}
        {vertrag.objektGesellschaftAdressePlz}{" "}
        {vertrag.objektGesellschaftAdresseOrt}
      </p>
    </>
  );
};

const ObjektAdresse: React.VFC<VertragProps> = ({ vertrag }) => {
  if (!vertrag) return null;
  return (
    <>
      <p>
        {vertrag.objektAdresseStrasse} {vertrag.objektAdresseHausnummer}
        <br />
        {vertrag.objektAdressePlz} {vertrag.objektAdresseOrt}
      </p>
    </>
  );
};

const Infobox: React.FC = (props) => {
  const { branding } = useBranding();

  return (
    <AlertTriangle variant="info">
      <div className="pt-3">{props.children}</div>
      Sollten Sie eine Ändererung wünschen, wenden Sie sich per E-Mail an:{" "}
      <a href={`mailto:${branding?.kontaktEmail}`}>
        {branding?.kontaktEmail}
      </a>{" "}
      oder rufen Sie bitte unsere Hotline{" "}
      <a href={`tel:${branding?.kontaktTelefon}`}>{branding?.kontaktTelefon}</a>{" "}
      an.
    </AlertTriangle>
  );
};

export default Gestattungsvertrag;
